import React from "react"
import Layout from "../../components/layout"

import newsPostStyles from "../../styles/templates/news-post.module.css"

import videoMarketing from "../../images/news/article-1/video-marketing.jpg"

const Article1Page = () => {
    return (
        <Layout location="news">
        <div className={ newsPostStyles.article }>
            <h1>VIDEOS MAKE YOU VISIBLE!</h1>
            <h3>November 26, 2020</h3>
            <div className={ newsPostStyles.landscape }>
                <img src={videoMarketing} alt=""/>
            </div>
            <p>Adding videos to your marketing communications armada will effectively engage customers and prospects and increase your sales.</p>
            <p>Videos create personal connections that static materials can never do. Videos keep people interested on your products and services. A US survey established that 52% of people exposed to videos report taking action, from asking for more information, setting an appointment or trying your offerings.</p>
            <p>True North ArtHouse makes creating your own videos easy, affordable and effective. All you have to do is visit our website <a href="http://truenortharthouse.com.ph/">http://truenortharthouse.com.ph/</a> or contact us at: (02) 3426-4844.</p>
            <button onClick={() => {
                window.history.back()
            }}>Go Back</button>
        </div>
        </Layout>
    )
}

export default Article1Page;